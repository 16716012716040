var api = "https://api.qingcigame.com/web";
var qq_group = "https://api.qingcigame.com/channel/join/group?game_id=48";

var obj = {
    index: function() {
        this.loading(); // loading
        this.bing(); // 绑定
        this.swiper();
        this.share();
    },
    loading: function() {
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        setTimeout(function() {
            layer.close(index);
            $('.index').removeClass('loading');
        }, 200);
    },
    bing: function() {
        $('.service-btn').click(function() {
            var parentClass = $(this).parent('.service-fload');
            parentClass.hasClass('on') ? parentClass.removeClass('on') : parentClass.addClass('on');
            if(parentClass.hasClass('on')) {
                obj.countNum('点击添加客服隐藏按钮');
            }
        });

        $('.year-btn').click(function() {
            $('.age-limit-wrap').fadeIn();
        });

        $('.monitor-btn').click(function() {
            var url = $(this).attr('_data');
            var name = $(this).attr('_name');
            obj.countNum(name);
            if(url !== undefined) {
                setTimeout(() => {
                    window.location.href = url;
                }, 500);
            }
        });

        $('.mobile-qq-btn').click(function() {
            $.ajax({
                url: qq_group,
                type: "post",
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    $('.qq-wrap img').attr('src', json.data.channel_url.qq_image);
                    $('.qq-wrap').fadeIn();
                },
                error: function() {
                    layer.msg("服务器请求失败", { time: 2000 });
                }
            })

        });  

        $('.qw-btn').click(function() {
            $('.qw-wrap').fadeIn();
        });        

        $('.close-btn').click(function() {
            $('.popup_wrap').fadeOut();
        });

        // 关闭下载提示
        $('.download_wrap').on('click', function () {
            $('.download_wrap').fadeOut();
        });

        
        var game_id = 48;
        $('.m-download-box').on('click', 'a' ,function() {
            $.ajax({
                type: "get",
                url: 'https://mapi.qingcigame.com/get_url?game_id='+game_id+'',
                dataType: 'json',
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    // 微信
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                        $('.download_wrap').fadeIn();
                        return
                    };
                    //安卓
                    if (/(Android)/i.test(navigator.userAgent)) {
                        if (json.data.android_down_url == '' || json.data.android_down_url == null) {
                            layer.msg('敬请期待！', {time:3000});
                        } else {
                            obj.countNum('移动端_点击安卓下载按钮');
                            location.href = json.data.android_down_url
                        }
                    };
                    //ios
                    if (/(iphone)/i.test(navigator.userAgent)) {
                        if (json.data.ios_down_url == '' || json.data.ios_down_url == null) {
                            layer.msg('敬请期待！', {time:3000});
                        } else {
                            obj.countNum('移动端_点击ios下载按钮');
                            location.href = json.data.ios_down_url
                        }
                    };
                }
            });
        })

        $('.pc-download-box').on('click', 'a' ,function() {
            var _this = $(this);
            $.ajax({
                type: "get",
                url: 'https://mapi.qingcigame.com/get_url?game_id='+game_id+'',
                dataType: 'json',
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    console.log(json)
                    if(_this.hasClass('ios-btn')) {
                        if (json.data.ios_down_url == '' || json.data.ios_down_url == null) {
                            layer.msg('敬请期待！', {time:3000});
                        } else {
                            obj.countNum('pc端_点击ios下载按钮');
                            location.href = json.data.ios_down_url
                        }
                    } else {
                        if (json.data.android_down_url == '' || json.data.android_down_url == null) {
                            layer.msg('敬请期待！', {time:3000});
                        } else {
                            obj.countNum('pc端_点击安卓下载按钮');
                            location.href = json.data.android_down_url
                        }
                    }
                }
            });
        })
    },
    swiper: function() {
        var swiperH = new Swiper('.swiper-container-h', {
            direction: 'vertical',
            // resistanceRatio: 0,
            mousewheelControl: true,
            speed: 200,
            slidesPerView: 'auto',
            onInit: function(swiper) { //Swiper2.x的初始化是onFirstInit
                swiperAnimateCache(swiper); //隐藏动画元素
                swiperAnimate(swiper); //初始化完成开始动画
                function Ani(btn, page) {
                    $(btn).on('click', function() {
                        swiperH.slideTo(page, 500, true);
                    });
                };

                var pageChange1 = Ani('.arrow', 1);
                var pageChange2 = Ani('.top-btn', 0);
            },
            onSlideChangeEnd: function(swiper) {
                swiperAnimate(swiper); //每个slide切换结束时也运行当前slide动画
            },
            onTouchEnd: function(swiper) {
                swiperAnimate(swiper);
            },
        })
        var mySwiper = new Swiper('.swiper-container', {
            // loop: true,
            initialSlide: 2,
            slidesPerView: 2,
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            // slidesPerView: 'auto',
            autoplayDisableOnInteraction: false,
            pagination: '.swiper-pagination',
            // autoplay: 5000,
            clickable: true,
            coverflow: {
                rotate: 0, // 旋转的角度
                stretch: 20, // 拉伸   图片间左右的间距和密集度
                depth: 88, // 深度   切换图片间上下的间距和密集度
                modifier: 6, // 修正值 该值越大前面的效果越明显
                slideShadows: false
            },
            nextButton: '.active_next',
            prevButton: '.active_prev',
        });
    },
    share: function() {
        var share_icon =
            "https://download4.qcplay.com/qcgame-api/UodAB9NqnBgPietgTq0YWNZhHvYlgSHSUUKT09fU.png";
        var share_big_title = "超喵星计划";
        var share_min_title = "收集全世界的可爱";
        var share_link = window.location.href;
        outside.share(share_icon, share_big_title, share_min_title, share_link);
    },
    // 阿里云埋点
    countNum: function(data) {
        setTimeout(() => {
            try {
                __bl.sum(data);
            } catch (e) {
                console.log(e)
            }
        }, 500);
    }
}